import { useNavigate, useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { Controller, useForm } from 'react-hook-form';
import { UpdateModelUnitFinancesBulk } from './models/bulkFinancesModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { updateFinancesModelUnitsBulkEndpoint } from './services/bulkFinancesService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { useEffect, useState } from 'react';
import { AdminAlerts } from '../../models/alertModel';
import { InvestorProjectDetail } from './models/investors';
import { getInvestorProjectDetailEndpoint } from './services/investorService';
import LoadingCard from '../../components/LoadingCard';

const InvestorProjectBulkFinances = () => {
  //token
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [project, setProject] = useState<InvestorProjectDetail>();

  const pageName = 'Actualización masiva de los datos financieros';
  // const pageParentName = 'Proyectos de preventa';
  const [pageParentName, setParentName] = useState('');

  useEffect(() => {
    callEndpoint(getInvestorProjectDetailEndpoint(id, token)).then((e) => {
      const response = fetchResponseAdapter(e.data);
      setProject(response.data);
      setParentName(project?.name ?? '');
    });
  }, [id]);

  //form

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UpdateModelUnitFinancesBulk>({
    resolver: yupResolver(
      yup
        .object({
          reserve: yup
            .number()
            .positive()
            .min(0)
            .required('Valor de reserva requerido'),
          collect_percentage: yup
            .number()
            .min(0)
            .required('Ingrese porcentaje a recolectar'),
        })
        .required(),
    ),
  });

  const [alerts, setAlerts] = useState<AdminAlerts | undefined>(undefined);

  const handleUpdateUnitsFinances = async (
    form: UpdateModelUnitFinancesBulk,
  ) => {
    const { data, status } = await callEndpoint(
      updateFinancesModelUnitsBulkEndpoint(
        id,
        {
          ...form,
        },
        token,
      ),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      //success
      //reload
      setAlerts({
        success: true,
        warning: false,
        title: 'Exito',
        text: 'Datos financieros actualizados con exito',
        onConfirm: () => {
          setAlerts(undefined);
          navigate(-1);
        },
      });
    } else {
      //error
      setAlerts({
        success: false,
        warning: true,
        title: 'Error',
        text: response.message,
        onConfirm: () => setAlerts(undefined),
      });
    }
  };

  if (!project || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={project.name}
      globalAlertOptions={alerts}
    >
      <Form role="form">
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor="canon">
                Canon
              </label>
              <Controller
                name="reserve"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Input
                    className={errors.reserve?.message && 'is-invalid'}
                    {...field}
                    type="number"
                    placeholder="Ingresa el valor de reserva"
                    step="any"
                  />
                )}
              />
              <h5 className="text-danger">{errors.reserve?.message}</h5>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="canon">
                Porcentaje a colectar (reserva + prima)
              </label>
              <Controller
                name="collect_percentage"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Input
                    className={
                      errors.collect_percentage?.message && 'is-invalid'
                    }
                    {...field}
                    type="number"
                    placeholder="Ingresa el porcentaje de monto a recolectar"
                    min={0}
                    max={100}
                    minLength={0}
                    maxLength={3}
                  />
                )}
              />
              <h5 className="text-danger">
                {errors.collect_percentage?.message}
              </h5>
            </FormGroup>
            <Button
              color={'warning'}
              onClick={handleSubmit(handleUpdateUnitsFinances)}
            >
              Actualizar datos financieros
            </Button>
          </Col>
        </Row>
      </Form>
    </AdminPage>
  );
};

export default InvestorProjectBulkFinances;
