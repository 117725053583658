import { Button, Modal } from 'reactstrap';

interface Props<T> {
  key?: string;
  isOpen: boolean;
  onToggle: () => void;
  onReset: () => void;
  onConfirmation: (payload: T) => void;
  headerText: string;
  children: JSX.Element;
  confirmationText?: string;
  abortText?: string;
  payload?: T;
  size?: 'sm' | 'lg' | 'xl';
  withFooterActions?: boolean;
}

const FormModal: React.FC<Props<any>> = ({
  isOpen,
  onToggle,
  onReset,
  children,
  headerText,
  onConfirmation,
  abortText,
  confirmationText,
  payload,
  size = 'sm',
  withFooterActions = true,
}) => {
  const onResetAux = () => {
    onReset();
    onToggle();
  };
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onResetAux()}
      size={size}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {headerText}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onResetAux()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body"> {children}</div>
      {withFooterActions && (
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            onClick={() => onConfirmation(payload)}
          >
            {confirmationText ?? 'Enviar'}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => onResetAux()}
          >
            {abortText ?? 'Cancelar'}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default FormModal;
