import { createSearchParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  CommissionPreviewForm,
  CommissionPreviewResponse,
} from '../models/commissionModel';
import { commissionDetailPreviewEndpoint } from '../services/commissionDetailService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { useState } from 'react';

const useCommissionDetail = () => {
  const { callEndpoint } = useCallApiAndLoad();
  const [apiErrors, setApiErrors] = useState<string | null>(null);

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const doCommissionDetailPreview = async (
    payload: CommissionPreviewForm,
  ): Promise<CommissionPreviewResponse[]> => {
    let result: CommissionPreviewResponse[] = [];

    const { data, status } = await callEndpoint(
      commissionDetailPreviewEndpoint(
        createSearchParams({
          canon: `${payload.canon}`,
          currency: `${payload.currency}`,
          period: `${payload.period}`,
          is_broker_supply: payload.is_broker_supply ? 'true' : 'false',
          //fill from payload
          coupon_percentage: `${payload.coupon_percentage / 100}`,
          commission_has_included_iva: `${payload.commission_has_included_iva}`,
          commission_has_broker_involved: `${payload.commission_has_broker_involved}`,
          admon_due: `${payload.admon_due}`,
          broker_register_type: `${payload.broker_register_type ?? ''}`,
        }).toString(),
        token,
      ),
    );

    const response = fetchResponseAdapter(data);

    if (status === 200) {
      //success
      result = response.data;
    } else {
      //error
      setApiErrors(response.message);
    }

    return result;
  };

  return {
    apiErrors,
    doCommissionDetailPreview,
  };
};

export default useCommissionDetail;
