import { useState } from 'react';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useAuth from '../../../hooks/useAuth';
import {
  CommissionDetail,
  CreateCommission,
  UpdateCommission,
} from '../models/commissionModel';
import {
  createCommissionEndpoint,
  retrieveCommissionEndpoint,
  updateCommissionEndpoint,
} from '../services/commissionService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { useNavigate } from 'react-router-dom';

const useCommission = () => {
  const navigate = useNavigate();
  const { callEndpoint } = useCallApiAndLoad();
  const [apiErrors, setApiErrors] = useState<string | null>(null);

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const doRetrieveCommissionDetail = async (
    commission_id: string,
  ): Promise<CommissionDetail> => {
    let result: CommissionDetail = null as any;

    const { data, status } = await callEndpoint(
      retrieveCommissionEndpoint(commission_id, token),
    );

    const response = fetchResponseAdapter(data);

    if (status === 200) {
      //success
      result = response.data;
    } else {
      //error
      setApiErrors(response.message);
    }

    return result;
  };

  const doCreateCommission = async (
    payload: CreateCommission,
  ): Promise<void> => {
    const { data, status } = await callEndpoint(
      createCommissionEndpoint(
        {
          ...payload,
          coupon_percentage: payload.coupon_percentage / 100,
        },
        token,
      ),
    );

    const response = fetchResponseAdapter(data);

    if (status === 201) {
      //success
      navigate(0);
    } else {
      //error
      setApiErrors(response.message);
    }
  };

  const doUpdateCommission = async (
    commission_id: string,
    payload: UpdateCommission,
  ): Promise<void> => {
    const { data, status } = await callEndpoint(
      updateCommissionEndpoint(
        commission_id,
        {
          ...payload,
          coupon_percentage: payload.coupon_percentage / 100,
        },
        token,
      ),
    );

    const response = fetchResponseAdapter(data);

    if (status === 200) {
      //success
      navigate(0);
    } else {
      //error
      setApiErrors(response.message);
    }
  };

  return {
    apiErrors,
    doRetrieveCommissionDetail,
    doCreateCommission,
    doUpdateCommission,
  };
};

export default useCommission;
