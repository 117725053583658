import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { CommissionPreviewForm } from '../models/commissionModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import {
  listToSelectOptionAdapter,
  plainListToSelectOptionAdapter,
} from '../../../adapters/listAdapter';
import { useEffect } from 'react';

interface Props {
  commission?: CommissionPreviewForm;
  handlePreview: (form: CommissionPreviewForm) => void;
  handleCreateOrUpdate: (form: CommissionPreviewForm) => void;
  isCalculatorMode?: boolean;
  isCreateMode?: boolean;
}

const CommissionForm: React.FC<Props> = ({
  handlePreview,
  handleCreateOrUpdate,
  commission,
  isCalculatorMode = true,
  isCreateMode = false,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<CommissionPreviewForm>({
    resolver: yupResolver(
      yup
        .object({
          canon: yup.number().required('Requerido'),
          admon_due: yup.number().default(0),
          coupon_percentage: yup.number().default(0),
          period: yup.number().default(12),
          is_broker_supply: yup.boolean().default(false),
          commission_has_included_iva: yup.boolean().default(false),
          commission_has_broker_involved: yup.boolean().default(false),
          currency: yup.string().required('Requerido').default('USD'),
          broker_register_type: yup
            .string()
            .nullable()
            .transform((value) => (value === '' ? '' : value)),
        })
        .required(),
    ),
  });

  useEffect(() => {
    if (commission) {
      reset({
        canon: commission.canon,
        admon_due: commission.admon_due,
        broker_register_type: commission.broker_register_type,
        commission_has_broker_involved:
          commission.commission_has_broker_involved,
        commission_has_included_iva: commission.commission_has_included_iva,
        coupon_percentage: commission.coupon_percentage * 100,
        currency: commission.currency,
        is_broker_supply: commission.is_broker_supply,
        period: commission.period,
      });
    }
  }, [commission]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const selectPeriod = plainListToSelectOptionAdapter([6, 12]);
  const selectCurrency = plainListToSelectOptionAdapter(['USD', 'GTQ']);

  const selectBrokerRegisterType = listToSelectOptionAdapter(
    [
      {
        label: 'No aplica',
        value: '',
      },
      {
        label: 'Broker registrado como sociedad (IVA)',
        value: 'broker_register_as_society',
      },
      {
        label: 'Broker registrado cómo persona natural (IVA + ISR)',
        value: 'broker_register_as_natural',
      },
      {
        label: 'Broker no registrado (ISR)',
        value: 'broker_no_register',
      },
    ],
    'value',
    'label',
  );

  //form

  return (
    <Form role="form">
      <Row>
        <Col md={8}>
          <FormGroup>
            <label className="form-control-label" htmlFor="canon">
              Canon
            </label>
            <Controller
              name="canon"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <Input
                  className={errors.canon?.message && 'is-invalid'}
                  {...field}
                  type="number"
                  placeholder="Ingresa el monto"
                  step="any"
                  readOnly={!isCalculatorMode}
                />
              )}
            />
            <h5 className="text-danger">{errors.canon?.message}</h5>
          </FormGroup>

          <FormGroup>
            <label className="form-control-label" htmlFor="currency">
              Moneda
            </label>
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <Select
                  options={selectCurrency}
                  placeholder="Selecciona moneda"
                  onChange={(event) => {
                    setValue('currency', event?.value);
                  }}
                  defaultValue={selectCurrency.find(
                    (c) => c.value === commission?.currency,
                  )}
                  isDisabled={!isCalculatorMode}
                />
              )}
            />
            <h5 className="text-danger">{errors.currency?.message}</h5>
          </FormGroup>

          <FormGroup>
            <label className="form-control-label" htmlFor="admon_due">
              Cuota de administración
            </label>
            <Controller
              name="admon_due"
              control={control}
              defaultValue={commission?.admon_due ?? 0}
              render={({ field }) => (
                <Input
                  className={errors.admon_due?.message && 'is-invalid'}
                  {...field}
                  type="number"
                  placeholder="Ingresa el monto"
                  step="any"
                />
              )}
            />
            <h5 className="text-danger">{errors.admon_due?.message}</h5>
          </FormGroup>

          <FormGroup>
            <label className="form-control-label" htmlFor="coupon_percentage">
              Cupón de descuento (0% - 100%)
            </label>
            <Controller
              name="coupon_percentage"
              control={control}
              defaultValue={commission?.coupon_percentage ?? 0}
              render={({ field }) => (
                <Input
                  className={errors.coupon_percentage?.message && 'is-invalid'}
                  {...field}
                  type="number"
                  placeholder="Ingresa el monto"
                  min={0}
                  max={100}
                  minLength={0}
                  maxLength={3}
                />
              )}
            />
            <h5 className="text-danger">{errors.coupon_percentage?.message}</h5>
          </FormGroup>

          {!commission && (
            <FormGroup>
              <label className="form-control-label" htmlFor="period">
                Periodo
              </label>
              <Controller
                name="period"
                control={control}
                render={({ field }) => (
                  <Select
                    options={selectPeriod}
                    placeholder="Selecciona un periodo"
                    onChange={(event) => {
                      setValue('period', event?.value);
                    }}
                  />
                )}
              />
              <h5 className="text-danger">{errors.period?.message}</h5>
            </FormGroup>
          )}

          <FormGroup>
            <Controller
              name="is_broker_supply"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="is_broker_supply"
                  type="checkbox"
                  className={errors.is_broker_supply?.message && 'is-invalid'}
                  style={{ marginLeft: 0 }}
                  onChange={(e) =>
                    setValue('is_broker_supply', e.target.checked)
                  }
                  defaultChecked={commission?.is_broker_supply}
                  disabled={!isCalculatorMode}
                />
              )}
            />

            <label
              htmlFor="is_broker_supply"
              className="form-control-label ml-3"
            >
              Es supply de broker
            </label>
            <h5 className="text-danger">{errors.is_broker_supply?.message}</h5>
          </FormGroup>

          <FormGroup>
            <Controller
              name="commission_has_included_iva"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="commission_has_included_iva"
                  type="checkbox"
                  className={
                    errors.commission_has_included_iva?.message && 'is-invalid'
                  }
                  style={{ marginLeft: 0 }}
                  onChange={(e) =>
                    setValue('commission_has_included_iva', e.target.checked)
                  }
                  defaultChecked={commission?.commission_has_included_iva}
                />
              )}
            />

            <label
              htmlFor="commission_has_included_iva"
              className="form-control-label ml-3"
            >
              Comisión incluye IVA
            </label>
            <h5 className="text-danger">
              {errors.commission_has_included_iva?.message}
            </h5>
          </FormGroup>
          <FormGroup>
            <Controller
              name="commission_has_broker_involved"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="commission_has_broker_involved"
                  type="checkbox"
                  className={
                    errors.commission_has_broker_involved?.message &&
                    'is-invalid'
                  }
                  style={{ marginLeft: 0 }}
                  onChange={(e) =>
                    setValue('commission_has_broker_involved', e.target.checked)
                  }
                  defaultChecked={commission?.commission_has_broker_involved}
                />
              )}
            />

            <label
              htmlFor="commission_has_broker_involved"
              className="form-control-label ml-3"
            >
              Demanda es de broker
            </label>
            <h5 className="text-danger">
              {errors.commission_has_broker_involved?.message}
            </h5>
          </FormGroup>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="broker_register_type"
            >
              Tipo de registro de broker
            </label>
            <Controller
              name="broker_register_type"
              control={control}
              render={({ field }) => (
                <Select
                  options={selectBrokerRegisterType}
                  placeholder="Selecciona tipo de broker"
                  onChange={(event) => {
                    setValue('broker_register_type', event?.value);
                  }}
                  defaultValue={selectBrokerRegisterType.find(
                    (p) => p.value === commission?.broker_register_type,
                  )}
                />
              )}
            />
            <h5 className="text-danger">
              {errors.broker_register_type?.message}
            </h5>
          </FormGroup>
          <div className="flex space-x-2">
            <Button
              color={'info'}
              onClick={() => {
                handleSubmit(handlePreview)();
              }}
            >
              Preview
            </Button>
            {!isCalculatorMode && (
              <Button
                color={'warning'}
                onClick={() => {
                  handleSubmit(handleCreateOrUpdate)();
                }}
              >
                {!isCreateMode ? 'Actualizar comisión' : 'Crear comisión'}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default CommissionForm;
