interface ITransactionPreSaleStatus {
  status: string;
  color: string;
  value: string;
}

export enum PROJECT_WORK_PROGRESS_ENUM {
  IN_PLANS = 'in_plans',
  IN_CONSTRUCTION = 'in_construction',
  FINALIZED = 'finalized',
}

export enum TRANSACTION_PRE_SALE_ENUM {
  ACTIVE = 'active',
  EXTERNAL = 'external',
}

export const PROJECT_WORK_PROGRESS = [
  {
    value: PROJECT_WORK_PROGRESS_ENUM.IN_PLANS,
    display_value: 'Preventa',
  },
  {
    value: PROJECT_WORK_PROGRESS_ENUM.IN_CONSTRUCTION,
    display_value: 'Construcción',
  },
  {
    value: PROJECT_WORK_PROGRESS_ENUM.FINALIZED,
    display_value: 'Terminado',
  },
];

export const TRANSACTION_PRE_SALE_STATUS: ITransactionPreSaleStatus[] = [
  {
    status: TRANSACTION_PRE_SALE_ENUM.ACTIVE,
    color: 'success',
    value: 'Activo',
  },
  {
    status: TRANSACTION_PRE_SALE_ENUM.EXTERNAL,
    color: 'danger',
    value: 'Vendido externo',
  },
];
