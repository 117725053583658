import axios from 'axios';
import { UpdateModelUnitFinancesBulk } from '../models/bulkFinancesModel';

const loadAbort = () => {
  return new AbortController();
};

export const updateFinancesModelUnitsBulkEndpoint = (
  projectId: string,
  payload: UpdateModelUnitFinancesBulk,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${projectId}/finances`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
