import { Button, Col, Row } from 'reactstrap';
import FormModal from '../../components/modals/FormModal';
import useModalState from '../../hooks/useModalState';
import useCommission from './hooks/useCommission';
import AdminListGroup, {
  DisplayItem,
} from '../../components/admin/AdminListGroup';
import { useState } from 'react';
import CommissionForm from './components/CommissionForm';
import { CommissionPreviewForm } from './models/commissionModel';
import useCommissionDetail from './hooks/useCommissionDetail';
import AdminCard from '../../components/admin/AdminCard';
import { ContractDetailData } from '../contracts/models/contractModel';
import { toNumber } from '../../adapters/numberAdapter';

interface Props {
  commission_id: string | null;
  contract: ContractDetailData;
}
const CommissionDetailModal = ({ commission_id, contract }: Props) => {
  //modal actions
  const [isOpenModal, toggleModal] = useModalState();
  const [isCreateMode, setCreateMode] = useState<boolean>(false);
  const [previewCommission, setPreviewCommission] = useState<DisplayItem[]>([]);
  const [commission, setCommission] = useState<CommissionPreviewForm>();

  const { doRetrieveCommissionDetail, doCreateCommission, doUpdateCommission } =
    useCommission();
  const { doCommissionDetailPreview } = useCommissionDetail();

  const handleCreateOrUpdate = async (form: CommissionPreviewForm) => {
    try {
      if (commission_id) {
        doUpdateCommission(commission_id, {
          ...form,
        });
      } else {
        doCreateCommission({
          ...form,
          transactionId: contract.transaction_id,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handlePreview = async (form: CommissionPreviewForm) => {
    try {
      const result = await doCommissionDetailPreview(form);
      const payload: DisplayItem[] = result.map((r) => {
        return {
          title: r.key,
          value: r.value,
        };
      });

      setPreviewCommission(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const commissionForm = (
    <CommissionForm
      handleCreateOrUpdate={handleCreateOrUpdate}
      handlePreview={handlePreview}
      commission={commission}
      key={'commission_form'}
      isCalculatorMode={false}
      isCreateMode={isCreateMode}
    />
  );

  const commissionDetailAux = (
    <>
      <Row>
        <Col>
          <AdminCard
            children={commissionForm}
            title="Formulario de comisiones"
          />
        </Col>
        <Col>
          <AdminListGroup
            title={'Detalle de comisiones'}
            items={previewCommission}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <Button
        color="info"
        onClick={() => {
          //load data if is true
          if (commission_id) {
            doRetrieveCommissionDetail(commission_id)
              .then((d) => {
                const preview: DisplayItem[] = d.commissionDetailInfo.map(
                  (dt) => {
                    return {
                      title: dt.description,
                      value: dt.value,
                    };
                  },
                );

                setPreviewCommission(preview);
                setCommission(d.commissionInfo);
              })
              .catch(console.error);
          } else {
            setCreateMode(true);
            setCommission({
              admon_due: 0,
              broker_register_type: '',
              canon: toNumber(contract.offer_price),
              commission_has_broker_involved: false,
              commission_has_included_iva: false,
              coupon_percentage: 0,
              currency: contract.currency ?? 'USD',
              is_broker_supply: contract.broker_id !== null,
              period: toNumber(contract.period),
            });
          }
          toggleModal();
        }}
      >
        Ver comisiones
      </Button>
      <FormModal
        children={commissionDetailAux}
        headerText="Detalle de comisión"
        isOpen={isOpenModal}
        onConfirmation={() => {}}
        onReset={() => {}}
        onToggle={toggleModal}
        size="xl"
        withFooterActions={false}
      />
    </>
  );
};

export default CommissionDetailModal;
