import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import { useCallback, useEffect, useState } from 'react';
import { getOfferDetailEndpoint } from '../../services/publicService';
import useAdminEntity from '../../hooks/useAdminEntity';
import {
  CreateContractExpress,
  OfferContractWCommission,
  OfferDetailData,
} from '../offers/models/offerModel';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { createContractV2WCommissionEndpoint } from '../offers/services/offerService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { checkOptions } from '../../constants/formConstants';
import AdminListGroup, {
  DisplayItem,
} from '../../components/admin/AdminListGroup';
import useCommissionDetail from '../commissions/hooks/useCommissionDetail';
import { CommissionPreviewForm } from '../commissions/models/commissionModel';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';
import Select from 'react-select';

const CreateContractV2: React.FC = () => {
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const navigate = useNavigate();
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const { apiErrors, doCommissionDetailPreview } = useCommissionDetail();

  const getOfferDetail = useCallback(
    () => getOfferDetailEndpoint(id, token),
    [id, token],
  );
  const [offer] = useAdminEntity<OfferDetailData>(
    getOfferDetail,
    setErrorAlert,
  );

  const [previewCommission, setPreviewCommission] = useState<DisplayItem[]>([]);

  //must validate this fields
  /*
  v1/admin/commission-detail/preview?
    canon=1000&
    admon_due=200&
    period=12&
    is_broker_supply=true&
    coupon_percentage=0&
    commission_has_included_iva=true&
    commission_has_broker_involved=true&
    currency=USD
*/

  const [commissionCoupon, setCommissionCoupon] = useState<number>(0.0);
  const [commissionAdmonDue, setCommissionAdmonDue] = useState<number>(0.0);
  const [commissionHasIva, setCommissionHasIva] = useState<boolean>(false);
  const [commissionWithBroker, setCommissionWithBroker] =
    useState<boolean>(false);
  const [hasRM, setHasRM] = useState<boolean>(false);
  const [brokerRegisterType, setBrokerRegisterType] = useState<string>('');

  const [signedAt, setSignedAt] = useState<string>(
    new Date().toISOString().split('T')[0],
  );
  const selectBrokerRegisterType = listToSelectOptionAdapter(
    [
      {
        label: 'No aplica',
        value: '',
      },
      {
        label: 'Broker registrado como sociedad (IVA)',
        value: 'broker_register_as_society',
      },
      {
        label: 'Broker registrado cómo persona natural (IVA + ISR)',
        value: 'broker_register_as_natural',
      },
      {
        label: 'Broker no registrado (ISR)',
        value: 'broker_no_register',
      },
    ],
    'value',
    'label',
  );

  const createContractWCommission = useCallback(
    async (payload: OfferContractWCommission) => {
      const { status, data } = await callEndpoint(
        createContractV2WCommissionEndpoint(payload, token),
      );
      if (status === 201) {
        const response: CreateContractExpress = fetchResponseAdapter(data).data;
        navigate(`/contracts/${response.transaction_id}/payment-status`);
      } else {
        setErrorAlert('Error no se pudo generar el contrato');
      }
    },
    [callEndpoint, setErrorAlert, navigate, token, offer, signedAt],
  );

  const commissionPreview = async () => {
    try {
      const payload: CommissionPreviewForm = {
        canon: offer.price,
        admon_due: commissionAdmonDue,
        period: offer.period,
        coupon_percentage: commissionCoupon,
        is_broker_supply: offer.broker_id ? true : false,
        commission_has_included_iva: commissionHasIva,
        commission_has_broker_involved: commissionWithBroker,
        currency: offer.currency,
        broker_register_type: brokerRegisterType,
      };

      const result = await doCommissionDetailPreview(payload);
      const response: DisplayItem[] = result.map((r) => {
        return {
          title: r.key,
          value: r.value,
        };
      });

      setPreviewCommission(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (apiErrors) {
      console.error(apiErrors);
      setErrorAlert(apiErrors);
    }
  }, [apiErrors]);

  const pageName = 'Crear contrato';
  const pageParentName = 'Contratos';
  if (!offer || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col md={6}>
          {/*<pre>{JSON.stringify(commissionHasIva)}</pre>*/}
          <Alert color="warning">
            Al generar el contrato de forma express, se generará un contrato SIN
            RENTAL MANAGEMENT , se generará exclusivamente 1 cuota en forma de
            DEPÓSITO ({offer.offer_price}), posteriormente se pueden generar las
            cuotas restantes ({offer.period})
          </Alert>

          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="admon_due_commission"
            >
              Cuota de administración
            </label>
            <Input
              type="number"
              id="commission_admin_due"
              onChange={(event) => {
                const commissionValue = parseFloat(event.target.value);
                setCommissionAdmonDue(commissionValue);
              }}
              defaultValue={commissionAdmonDue}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="coupon_commission">
              Cupón de descuento (0% - 100%)
            </label>
            <Input
              type="number"
              id="commission_coupon"
              min={0}
              max={100}
              minLength={0}
              maxLength={3}
              onChange={(event) => {
                const commissionValue = parseFloat(event.target.value);
                setCommissionCoupon(commissionValue);
              }}
              defaultValue={commissionCoupon}
            />
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md={4}>
                <label className="form-control-label" htmlFor="is_furnished">
                  IVA incluido
                </label>
              </Col>
              <Col md={8}>
                {checkOptions &&
                  checkOptions.map((item: any) => (
                    <div
                      key={`has_iva-${item.name}`}
                      className="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        className="custom-control-input"
                        type="radio"
                        name="has_commission_iva"
                        value={item.value}
                        defaultChecked={
                          item.value === (commissionHasIva || false)
                        }
                        id={`has_iva-${item.name}`}
                        onChange={(event) => {
                          const hasIva = event.currentTarget.value === 'true';
                          setCommissionHasIva(hasIva);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`has_iva-${item.name}`}
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label
                  className="form-control-label"
                  htmlFor="commission_broker"
                >
                  Comisión compartida (broker)
                </label>
              </Col>
              <Col md={8}>
                {checkOptions &&
                  checkOptions.map((item: any) => (
                    <div
                      key={`has_broker-${item.name}`}
                      className="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        className="custom-control-input"
                        type="radio"
                        name="has_commission_broker"
                        value={item.value}
                        defaultChecked={
                          item.value === (commissionWithBroker || false)
                        }
                        id={`has_broker-${item.name}`}
                        onChange={(event) => {
                          const hasIva = event.currentTarget.value === 'true';
                          setCommissionWithBroker(hasIva);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`has_broker-${item.name}`}
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <label
              htmlFor="broker_register_type"
              className="form-control-label"
            >
              Tipo de registro de broker
            </label>
            <Select
              options={selectBrokerRegisterType}
              placeholder="Selecciona tipo de broker"
              onChange={(event) => {
                setBrokerRegisterType(event?.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Input
              type="checkbox"
              defaultChecked={hasRM}
              onChange={() => {
                setHasRM(!hasRM);
              }}
              style={{ marginLeft: 0 }}
            />
            <label htmlFor="has_rm" className="form-control-label ml-3">
              Tiene rental management
            </label>
          </FormGroup>

          <FormGroup>
            <label htmlFor="signed_at" className="form-control-label">
              Fecha de firma
            </label>
            <Input
              type="date"
              value={signedAt}
              onChange={(e) => {
                setSignedAt(e.target.value);
              }}
            />
          </FormGroup>

          <Button
            color={'primary'}
            onClick={() => {
              createContractWCommission({
                coupon_percentage: commissionCoupon / 100,
                commission_has_included_iva: commissionHasIva,
                commission_has_broker_involved: commissionWithBroker,
                admon_due: commissionAdmonDue,
                offer_id: id,
                has_rental_management: hasRM,
                period: offer.period,
                signed_at: signedAt,
                broker_register_type: brokerRegisterType,
              });
            }}
            disabled={isLoading}
          >
            Crear contrato con comisión
          </Button>
        </Col>
        <Col>
          <AdminListGroup
            title={`Detalle de distribución de ganancias [${
              offer.broker_id
                ? 'La propiedad es de supply de BROKER'
                : 'La propiedad es supply de PROPI'
            }]`}
            items={previewCommission}
            footer={
              <Button color={'info'} type="button" onClick={commissionPreview}>
                Preview
              </Button>
            }
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default CreateContractV2;
