import AdminPage from '../../components/admin/AdminPage';
import React, { useCallback, useState } from 'react';
import { Row, Col } from 'reactstrap';
import AdminUploadFile from '../../components/admin/AdminUploadFile';
import { FilePreviewTypes } from '../../constants/formConstants';
import { uploadPropertySalesFileEndpoint } from '../properties/services/propertySalesService';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createModelUnitsInBulk,
  createModelUnitsTakenNotTakenInBulk,
} from './services/investorService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';

const ModelUnitBulkCreate: React.FC = () => {
  const { id: modelId = '' } = useParams();
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate();

  const [errorAlert, setErrorAlert] = useState<string>('');
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };

  const pageName = 'Crear unidades a través de CSV';
  const parentPageName = 'Preventa';

  const uploadCSVForProcessing = useCallback(async (files: Array<any>) => {
    if (!files.length) {
      setErrorAlert('Error, debes de adjuntar un archivo para subir');
      return;
    }
    const formData = new FormData();
    formData.append('model_unit_file', files[0]);
    const { status } = await callEndpoint(
      createModelUnitsInBulk(formData, modelId, token),
    );
    if (status === 201) {
      navigate(`/investors/models/${modelId}/model-units`); //make a reload
    } else {
      setErrorAlert(
        'Lo sentimos, por el momento no es posible completar la acción',
      );
    }
  }, []);

  const uploadCSVTakenNotTaken = useCallback(async (files: Array<any>) => {
    if (!files.length) {
      setErrorAlert('Error, debes de adjuntar un archivo para subir');
      return;
    }
    const formData = new FormData();
    formData.append('model_unit_file', files[0]);
    const { status } = await callEndpoint(
      createModelUnitsTakenNotTakenInBulk(formData, modelId, token),
    );
    navigate(`/investors/models/${modelId}/model-units`); //make a reload
  }, []);

  return (
    <AdminPage
      name={pageName}
      parentName={parentPageName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <AdminUploadFile
            handleUpload={uploadCSVForProcessing}
            description={'Subir CSV'}
            dropZoneText={'Subir CSV'}
            maxFiles={1}
            filesPreviewType={FilePreviewTypes.COUNT}
          />
        </Col>
        <Col>
          <AdminUploadFile
            handleUpload={uploadCSVTakenNotTaken}
            description={'Subir CSV con asignación de usuarios '}
            dropZoneText={`Subir CSV (versión taken not taken)`}
            maxFiles={1}
            filesPreviewType={FilePreviewTypes.COUNT}
            templateUrl="https://storage.googleapis.com/assets-us-east4-propilatam/admin/templates/taken-not-taken%20-%20template.csv"
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default ModelUnitBulkCreate;
