import React, { useCallback } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

export interface DisplayItem {
  title: any;
  value: any;
  href?: string;
  key?: string;
}

export interface Props {
  items: DisplayItem[];
  title: any;
  footer?: any;
}
const AdminListGroup: React.FC<Props> = ({ items, title, footer }) => {
  const navigate = useNavigate();

  const buildItem = useCallback(
    (item: DisplayItem, idx: number) => (
      <ListGroupItem key={item?.key || `${item.title}-${idx}`}>
        <Row
          className="align-items-center"
          onClick={() => (item.href ? navigate(`${item.href}`) : false)}
        >
          <div className="col ml--2">
            <h4 className="mb-0">{item.title}</h4>
          </div>
          <Col className="col-auto">
            <span className="mr-2">
              {item.value}
              {item.href && (
                <Button color="" size="sm" type="button">
                  <i className="ni ni-bold-right pt-1" />
                </Button>
              )}
            </span>
          </Col>
        </Row>
      </ListGroupItem>
    ),
    [navigate],
  );

  const buildEmptyItem = (
    <ListGroupItem key={`empty-key`}>
      <Row>
        {' '}
        <div className="col ml--2">
          <h4 className="mb-0">Sin detalle</h4>
        </div>
      </Row>
    </ListGroupItem>
  );

  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <h6 className="heading-small text-muted mt-4 mb-4">{title}</h6>
          {items && items.map((item, idx) => buildItem(item, idx))}
          {items.length === 0 && buildEmptyItem}
        </ListGroup>
      </CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
};

export default AdminListGroup;
