export enum PROJECT_FACADE_DISTRIBUTION_ENUM {
  AVAILABLE = 'available',
  COMING_SOON = 'coming_soon',
  SOLD = 'sold',
  UNAVAILABLE = 'unavailable',
}

export const PROJECT_DISTRIBUTION_LIST = [
  {
    value: PROJECT_FACADE_DISTRIBUTION_ENUM.AVAILABLE,
    display_value: 'Disponible',
  },
  {
    value: PROJECT_FACADE_DISTRIBUTION_ENUM.COMING_SOON,
    display_value: 'Próximamente',
  },
  {
    value: PROJECT_FACADE_DISTRIBUTION_ENUM.SOLD,
    display_value: 'Vendido',
  },
  {
    value: PROJECT_FACADE_DISTRIBUTION_ENUM.UNAVAILABLE,
    display_value: 'No Disponible',
  },
];
