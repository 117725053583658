import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import useProjectTowerLayout from './hooks/useProjectTowerLayout';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';
import { PROJECT_DISTRIBUTION_LIST } from './constants/towerLayoutConstants';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Option } from '../../models/formModel';
import useCreateImageTower from './hooks/useCreateImageTower';

const InvestorProjectTowerLayout = () => {
  const { id = '' } = useParams();

  const distributionStatusOptions = listToSelectOptionAdapter(
    PROJECT_DISTRIBUTION_LIST,
    'value',
    'display_value',
  );
  const {
    handleSubmit,
    sendLevelToUpdate,
    isEditing,
    errors,
    control,
    setValue,
    isLoading,
    sendLevelToCreate,
    resetForm,
    canvasRef,
    handleClick,
    setIsEditing,
    selectPolygonStatus,
    points,
    towerInfo,
    loadPolygonTowerData,
    register,
    imageSize,
  } = useProjectTowerLayout();

  const {
    addCurrentImage,
    handleSubmit: handleSubmitImage,
    setValue: setValueImage,
  } = useCreateImageTower();

  useEffect(() => {
    loadPolygonTowerData(id);
  }, [id]);

  const handleFileChange = (event: any) => {
    event.stopPropagation();
    const file = event.target.files?.[0] || null;
    setValueImage('cover', file);
  };

  const handleImageSubmit = (form: any) => {
    const formData = new FormData();
    if (form.cover) {
      formData.append('cover', form.cover);
    }
    addCurrentImage(formData);
  };

  return (
    <AdminPage name={'Configuración de layout Torre'} parentName={`Proyecto`}>
      <Row>
        {/* Upload facade background */}
        <Form
          className="w-100"
          encType={'multipart/form-data'}
          onSubmit={handleSubmitImage(handleImageSubmit)}
        >
          <Col md="6" className="mx-auto">
            {towerInfo?.facade_background === null && (
              <div className="w-full">
                <Alert color="warning">
                  <b>Atención:</b> La imagen de la torre aun no ha sido asignada
                  para crear poligonos
                </Alert>
              </div>
            )}
            <p className="fw-bolder">Asignar imagen de la torre</p>
            <div className="custom-file">
              <Input
                id="exampleFile"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
            <hr />
            <div className="col-md-12 d-flex justify-content-center">
              <Button color="primary">Subir imagen</Button>
            </div>
          </Col>
          <hr />
        </Form>
        {towerInfo && towerInfo?.distribution?.length > 0 && (
          <Col
            sm="8"
            className={`mb-4  d-flex ${
              selectPolygonStatus
                ? 'justify-content-around'
                : 'justify-content-center'
            } mx-auto`}
          >
            <div className="d-flex justify-content-center">
              <Button color="primary" onClick={() => setIsEditing(true)}>
                Actualizar poligonos
              </Button>
              {isEditing && (
                <Button color="" onClick={() => setIsEditing(false)}>
                  Quitar edicion
                </Button>
              )}
            </div>
            <div>
              {selectPolygonStatus && (
                <Button
                  onClick={() => {
                    window.open(
                      `/investors/projects/${id}/tower-layout/floor-layout/${selectPolygonStatus?.id}`,
                      '_self',
                    );
                  }}
                  color="success"
                >
                  Asignar poligonos a nivel
                </Button>
              )}
            </div>
          </Col>
        )}

        {/* Canvas */}

        <Col md="12" className="text-center">
          <canvas
            ref={canvasRef}
            width={imageSize?.width || 1440}
            height={imageSize?.height || 854}
            onClick={handleClick}
            style={{ cursor: 'crosshair' }}
          />
        </Col>

        {/* Level form */}
        {towerInfo?.facade_background !== null && (
          <Col md="12">
            {/* <Button onClick={sendLevelToCreate}>Generar</Button> */}
            <Form
              role="form"
              onSubmit={handleSubmit(
                isEditing ? sendLevelToUpdate : sendLevelToCreate,
              )}
            >
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Selecciona el nivel del piso a crear
                    </label>
                    <Controller
                      name="level"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className={errors.level?.message && 'is-invalid'}
                          {...field}
                          type="number"
                        />
                      )}
                    />
                    <h5 className="text-danger">{errors.level?.message}</h5>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="quotas">
                      Estado del piso
                    </label>

                    <Controller
                      name="status"
                      control={control}
                      render={({ field: { ref, value } }) => (
                        <FormGroup>
                          <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            onChange={(e: any) => {
                              setValue('status', e.target.value);
                            }}
                          >
                            <option>Seleccionar</option>
                            {distributionStatusOptions?.map((item: Option) => (
                              <option
                                value={item.value}
                                selected={
                                  selectPolygonStatus?.value === item.value
                                }
                              >
                                {item.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      )}
                    />
                    <h5 className="text-danger">{errors.status?.message}</h5>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Button color="primary" disabled={isLoading}>
                    {isEditing ? 'Actualizar nivel' : 'Crear nivel'}
                  </Button>
                  <Button onClick={resetForm} color="warning">
                    Limpiar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
      </Row>
    </AdminPage>
  );
};

export default InvestorProjectTowerLayout;
