import { Card, CardBody, CardHeader } from 'reactstrap';

export interface Props {
  title: string;
  children: JSX.Element;
}

const AdminCard = ({ children, title }: Props) => {
  return (
    <Card>
      <CardBody>
        <h6 className="heading-small text-muted mt-4 mb-4">{title}</h6>
        {children}
      </CardBody>
    </Card>
  );
};

export default AdminCard;
